import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BillingAccountWithMetadata, BillingService } from '../billing.service';
import { first, takeUntil } from 'rxjs/operators';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  BillingBundleStatus,
  BillingProductPackageType,
  CreateBillingAccount,
  CreditType,
  Roles,
  Team,
  UpdateBillingAccount
} from '@connect-our-kids/connect-our-kids-lib/generated/graphql';
import { TeamUserService, TeamWithMetadata } from '../services/team-user.service';
import { combineLatest, merge, Subject } from 'rxjs';
import { TeamService } from '../team.service';
import { AuthService } from '../auth.service';

@Component({
  selector: 'billing-accounts',
  templateUrl: './billing-accounts.component.html',
  styleUrls: ['./billing-accounts.component.scss'],
})
export class BillingAccountsComponent implements OnInit, OnDestroy {

  public readonly BillingProductPackageType = BillingProductPackageType;

  private readonly ON_DESTROY = new Subject<void>();
  private readonly ON_BILLING_ACCOUNTS_CHANGE = new Subject<void>();

  @ViewChild('billingAccountSettingsModal', { static: true })
  public billingAccountSettingsModal: ElementRef;

  @ViewChild('deleteBillingAccountConfirmationModal', { static: true })
  public deleteBillingAccountConfirmationModal: ElementRef;

  @ViewChild('addCreditsModal', { static: true })
  public addCreditsModal: ElementRef;

  public loadingBillingAccounts = false;
  public loadingSummaryInfo = false;

  public displayedBillingAccounts: BillingAccountWithMetadata[] = [];
  public savingBillingAccount: boolean = false;
  public deletingBillingAccount: boolean = false;

  public billingAccountForm = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
    description: new UntypedFormControl('', [Validators.required]),
    emailAddress: new UntypedFormControl('', [Validators.required, Validators.email]),
    team: new UntypedFormControl(undefined, [Validators.required]),
    isDefault: new UntypedFormControl(false, []),
  });

  public selectedTeam: TeamWithMetadata;
  public activeBillingAccount: BillingAccountWithMetadata;

  public teams: Team[] = [];
  public loadingTeams = false;

  public selectedBundle: BillingProductPackageType;
  public redirectingToStripe = false;
  private billingAccounts: BillingAccountWithMetadata[] = [];

  public personalBillingAccount: BillingAccountWithMetadata;

  public numberOfAccessibleBillingAccounts: number;
  public totalAccessibleCredits: number;
  public totalActiveBundles: number;
  public nextCreditBundleExpirationDate: Date;

  private selectedBillingAccount: BillingAccountWithMetadata;

  constructor(
    private modal: NgbModal,
    private billingService: BillingService,
    private teamService: TeamService,
    private teamUserService: TeamUserService,
    private authService: AuthService
  ) { }

  public ngOnInit(): void {
    this.loadBillingAccounts();
    this.loadBundles();

    this.loadingTeams = true;
    combineLatest([
      this.authService.getUser(),
      this.billingService.getSelectedBillingAccount(),
      this.teamUserService.getUserTeams()
    ])
    .pipe(takeUntil(this.ON_DESTROY))
    .subscribe(([user, selectedBillingAccount, teams]) => {
      this.loadingTeams = false;
      this.teams = teams;

      let selectedTeamId = selectedBillingAccount.teamId;
      if (!selectedTeamId) {
        selectedTeamId = this.teams[0].id;
      }

      const userTeam = user.userTeams.find(userTeam => userTeam.team.id === selectedTeamId);
      this.selectTeam({
        ...userTeam.team,
        isUserManager: userTeam ? userTeam.role === Roles.MANAGER : false
      });
    }, () => this.loadingTeams = false);
  }

  public ngOnDestroy(): void {
    this.ON_DESTROY.next();

    this.ON_DESTROY.complete();
    this.ON_BILLING_ACCOUNTS_CHANGE.complete();
  }

  public openCreateBillingAccountModal() {
    this.modal.open(this.billingAccountSettingsModal, {
      size: 'md',
      centered: true,
      beforeDismiss: () => {
        return true;
      }
    });
  }

  public saveBillingAccount(): void {
    if (this.savingBillingAccount) {
      return;
    }

    this.savingBillingAccount = true;

    if (!this.billingAccountForm.valid) {
      this.billingAccountForm.markAllAsTouched();
      this.billingAccountForm.markAsDirty();
      this.savingBillingAccount = false;
      return;
    }

    this.createBillingAccount();
  }

  public createBillingAccount(): void {
    const controls = this.billingAccountForm.controls;
    const billingAccountCreationPayload: CreateBillingAccount = {
      name: controls.name.value,
      emailAddress: controls.emailAddress.value,
      description: controls.description.value,
      isDefault: controls.isDefault.value,
    };

    this.billingService.createBillingAccount(controls.team.value.id, billingAccountCreationPayload)
      .subscribe(
        () => {
          this.close();
          this.savingBillingAccount = false;
        },
        () => {
          this.savingBillingAccount = false;
        }
      );
  }

  public editBillingAccount(): void {
    const controls = this.billingAccountForm.controls;
    const billingAccountUpdatePayload: UpdateBillingAccount = {
      name: controls.name.value,
      emailAddress: controls.emailAddress.value,
      description: controls.description.value,
      isDefault: controls.isDefault.value,
    };

    this.billingService.updateBillingAccount(this.selectedTeam.id, this.selectedBillingAccount.id, billingAccountUpdatePayload)
      .subscribe(
        () => {
          this.close();
          this.savingBillingAccount = false;
        },
        () => {
          this.savingBillingAccount = false;
        }
      );
  }

  public setActiveBillingAccount(billingAccount: BillingAccountWithMetadata): void {
    this.billingService.setSelectedBillingAccount(billingAccount);
  }

  public deleteBillingAccount(): void {
    this.deletingBillingAccount = true;
    this.billingService.deleteBillingAccount(this.selectedTeam.id, this.selectedBillingAccount.id)
      .subscribe(
        () => {
          this.close();
          this.deletingBillingAccount = false;
        },
        () => {
          this.deletingBillingAccount = false;
        }
      );
  }

  public close(): void {
    this.selectedBillingAccount = null;
    this.modal.dismissAll();
  }

  public selectBundleToPurchase(bundleType: BillingProductPackageType) {
    if (this.redirectingToStripe) {
      return;
    }

    this.selectedBundle = bundleType;
  }

  public openCheckoutSession(): void {
    this.redirectingToStripe = true;
    this.billingService.billingCheckoutSession(this.selectedBillingAccount, this.selectedBundle).subscribe((session) => {
      window.location.href = session.url;
      this.close();
    });
  }

  public get emailError(): string {
    return this.billingAccountForm.controls.emailAddress.hasError('email') ? 'Please enter a valid email' : 'Please enter an email for the account!';
  }

  public get billingAccountFormHasError(): boolean {
    const allFieldsTouched =
      this.billingAccountForm.controls.emailAddress.touched &&
      this.billingAccountForm.controls.name.touched &&
      this.billingAccountForm.controls.description.touched;

    return allFieldsTouched && this.billingAccountForm.invalid;
  }

  public changeTeamInAccountCreationForm(team: Team): void {
    this.billingAccountForm.controls.team.setValue(team);
  }

  private loadBillingAccounts(): void {
    this.loadingBillingAccounts = true;
    this.billingService.getBillingAccounts()
      .pipe(takeUntil(this.ON_DESTROY))
      .subscribe((accounts) => {
        this.numberOfAccessibleBillingAccounts = accounts.length;

        this.personalBillingAccount = accounts.find(account => account.type === CreditType.PERSONAL);
        this.billingAccounts = accounts.filter(account => account.type === CreditType.BILLING_ACCOUNT);
        this.ON_BILLING_ACCOUNTS_CHANGE.next();

        this.filterBillingAccountsOnSelectedTeamChange();

        this.billingService.getSelectedBillingAccount()
          .pipe(first())
          .subscribe((account) => {
            this.activeBillingAccount = account;
            this.loadingBillingAccounts = false;
          });
      });
  }

  private filterBillingAccountsOnSelectedTeamChange(): void {
    const componentDestructionOrBillingAccountsChange = merge(this.ON_DESTROY, this.ON_BILLING_ACCOUNTS_CHANGE);
    this.teamService.getSelectedTeam()
      .pipe(takeUntil(componentDestructionOrBillingAccountsChange))
      .subscribe((team) => this.selectTeam(team));
  }

  private selectTeam(team: TeamWithMetadata): void {
    this.selectedTeam = team;
    this.filterBillingAccountsForSelectedTeam();
  }

  private filterBillingAccountsForSelectedTeam(): void {
    this.displayedBillingAccounts = this.billingAccounts
      .filter((account) => !this.selectedTeam || account.teamId === this.selectedTeam.id);

    this.orderDisplayedBillingAccounts();
  }

  private orderDisplayedBillingAccounts(): void {
    this.displayedBillingAccounts.sort(this.orderByCreditsCount);
  }

  private orderByCreditsCount(firstAccount: BillingAccountWithMetadata, secondAccount: BillingAccountWithMetadata): number {
    return secondAccount.creditsCount - firstAccount.creditsCount;
  }

  private loadBundles() {
    this.loadingSummaryInfo = true;
    this.billingService.getBillingCreditsBundles()
      .pipe(takeUntil(this.ON_DESTROY))
      .subscribe(bundles => {
        this.totalAccessibleCredits = 0;
        this.totalActiveBundles = 0;

        const usableBundlesSortedByExpirationDateDesc = bundles
          .filter(bundle => bundle.status === BillingBundleStatus.ACTIVE || bundle.status === BillingBundleStatus.NEW)
          .sort((a, b) => new Date(a.expirationDate).getTime() - new Date(b.expirationDate).getTime());

        if (usableBundlesSortedByExpirationDateDesc.length > 0) {
          this.nextCreditBundleExpirationDate = bundles[0].expirationDate;
          this.totalAccessibleCredits = usableBundlesSortedByExpirationDateDesc.reduce((sum, bundle) => sum += bundle.creditsCount, 0);
          this.totalActiveBundles = usableBundlesSortedByExpirationDateDesc.length;
        }

        this.loadingSummaryInfo = false;
      });
  }
}
