<div class="container p-0 mt-4">
  <div class="row">
    <div class="col-lg-5 col-md-12">
      <div class="welcome-wrapper d-flex align-items-center">
        <div class="d-flex flex-row bd-highlight">
          <div class="py-2 bd-highlight">
            <img src="../../assets/bill.png">
          </div>
          <div class="p-2 bd-highlight">
            <h5>Welcome to Billing!</h5>
            <p>Here you can set up billing accounts to use when purchasing People Search credits for yourself (Personal Billing Account) and/or your team (Team Billing Accounts). You can also click into each individual billing account to make changes, see more details about existing credit bundles, expirations and purchases.
              Please <a href="https://help.connectourkids.org/en/articles/8594384-service-and-support-subscription-and-people-search-bundles-available-for-purchase">click here</a> to learn more.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-7 col-md-12">
      <div class="summary-wrapper">
        <h5 class="d-flex align-items-center">Summary</h5>
          <div class="col-lg-12">
            <div class="summary-box">
              <div *ngIf="!loadingSummaryInfo; else loadingTemplate" class="active-task">
                <img src="../../assets/total-credits.png">
                Total number of credits accessible
                <span>{{totalAccessibleCredits}}</span>
              </div>
              <div *ngIf="!loadingSummaryInfo; else loadingTemplate" class="complete-task">
                <img src="../../assets/accounts.png">
                Total number of billing accounts accessible
                <span>{{numberOfAccessibleBillingAccounts}}</span>
              </div>
              <div *ngIf="!loadingSummaryInfo; else loadingTemplate" class="personal-task">
                <img src="../../assets/active-bundle.png">
                Total number of active credit bundles
                <span>{{totalActiveBundles}}</span>
              </div>
              <div *ngIf="!loadingSummaryInfo; else loadingTemplate" class="case-task">
                <img src="../../assets/bundle-date.png">
                Next credit bundle expiration date
                <span>{{nextCreditBundleExpirationDate ? (nextCreditBundleExpirationDate | date: 'MM/dd/YYYY HH:mm') : '--'}}</span>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12 px-0">
      <div class="d-flex justify-content-between align-items-center mt-5 mb-4">
          <a class="page-title">Billing Accounts</a>
        </div>
        <div class="personal-billing">
          <div class="personal-title">Personal</div>
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">Account Name</th>
                <th scope="col" class="text-center">Credits</th>
                <th scope="col" class="text-right">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngIf="personalBillingAccount">
                <td scope="row">Personal</td>
                <td scope="row" class="text-center">{{personalBillingAccount.creditsCount}}</td>
                <td class="text-right">
                  <button class="btn btn-primary" [routerLink]="['/billing/personal']">More billing details</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="team-billing">
          <div class="team-title">Team</div>
          <div class="d-flex align-items-center mb-3">
            <div ngbDropdown class="d-inline-block team-dropdown mr-2">
              <button [disabled]="loadingTeams" type="button" class="btn" id="teamDropdown" ngbDropdownToggle>
                  {{ selectedTeam ? selectedTeam.name : 'Team' }}
              </button>
              <div ngbDropdownMenu aria-labelledby="teamDropdown">
                  <button ngbDropdownItem *ngFor="let team of teams" (click)="selectTeam(team)"> {{ team.name }} </button>
              </div>
            </div>

            <div class="btn-wrapper">
              <button [disabled]="loadingBillingAccounts || !selectedTeam?.isUserManager" type="button" class="btn btn-primary main-user-button" (click)="openCreateBillingAccountModal()">Create billing account</button>
            </div>
          </div>

          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">Account Name</th>
                <th scope="col" class="text-center">Credits</th>
                <th scope="col" class="text-right">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let billingAccount of displayedBillingAccounts">
                <td scope="row">{{billingAccount.name}}</td>
                <td scope="row" class="text-center">{{billingAccount.creditsCount}}</td>
                  <td class="text-right">
                    <button [routerLink]="['/billing/' + billingAccount.id]" class="btn btn-primary">More billing details</button>
                  </td>
              </tr>
            </tbody>
          </table>
        </div>
    </div>
  </div>
</div>

<ng-template #billingAccountSettingsModal id="create-billing-modal" class="modal">
  <div class="wrapper">
    <div class="modal-wrapper">
      <form [formGroup]="billingAccountForm">
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title">Create Billing Account</h4>
        </div>
        <div class="modal-body py-3">
          <div class="pb-4">
            <div ngbDropdown class="team-dropdown modal-team-dropdown">
              <button [disabled]="loadingTeams" type="button" class="btn" id="teamDropdown" ngbDropdownToggle>
                {{ billingAccountForm.controls["team"].value ? billingAccountForm.controls.team.value.name : 'Team' }}
              </button>
              <div ngbDropdownMenu aria-labelledby="teamDropdown">
                <button ngbDropdownItem *ngFor="let team of teams" (click)="changeTeamInAccountCreationForm(team)"> {{ team.name }} </button>
              </div>
              <span class="error-txt"
                    *ngIf="billingAccountForm.controls.team.touched && billingAccountForm.controls.team.errors">Please select a team</span>
            </div>
          </div>

          <div class="pb-4">
            <label class="mb-1">Name of the Account</label>
            <input type="text" class="form-control" formControlName="name" placeholder="Name of the Account" />
            <span class="error-txt"
              *ngIf="billingAccountForm.controls.name.touched && billingAccountForm.controls.name.errors">Please
              provide a name for the account!</span>
          </div>

          <div class="pb-4">
            <label class="mb-1">Email Address</label>
            <input type="email" class="form-control" formControlName="emailAddress" placeholder="Email Address" />
            <span class="error-txt"
              *ngIf="billingAccountForm.controls.emailAddress.touched && billingAccountForm.controls.emailAddress.errors">{{emailError}}</span>
          </div>

          <div class="pb-4">
            <label class="mb-1">Description</label>
            <textarea type="text" class="form-control" formControlName="description"
              placeholder="Description"></textarea>
            <span class="error-txt"
              *ngIf="billingAccountForm.controls.description.touched && billingAccountForm.controls.description.errors">Please
              provide a description for the account!</span>
          </div>

          <!-- <div class="pb-4">
            <label for="isDefaultToggle" class="mb-1 mr-1">Is Default</label>
            <mat-slide-toggle id="isDefaultToggle" formControlName="isDefault"></mat-slide-toggle>
            <mat-icon
              matTooltip="Set as default account if you want all of your team to use this as primary account."
              matTooltipPosition="above"
              aria-label="Button that configures whether the account is the primary one for the team">
              info
            </mat-icon>
          </div> -->

        </div>
        <div class="modal-footer justify-content-between">
          <button [disabled]="loadingBillingAccounts" type="button" class="btn btn-secondary" (click)="close()">Cancel</button>
          <button type="button" class="btn btn-primary" (click)="saveBillingAccount()"
            [disabled]="billingAccountFormHasError || savingBillingAccount">
            Save
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>

<ng-template #deleteBillingAccountConfirmationModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Confirm Delete</h4>
  </div>
  <div class="modal-body py-4">
        <p class="mb-0">Are you sure you want to delete this Billing Account?</p>
  </div>
  <div class="modal-footer justify-content-between">
    <button [disabled]="deletingBillingAccount" type="button" class="btn btn-secondary" (click)="close()">Cancel</button>
    <button [disabled]="deletingBillingAccount" type="button" class="btn btn-danger" (click)="deleteBillingAccount()">Delete</button>
  </div>
</ng-template>

<ng-template #addCreditsModal id="demo-modal" class="modal">
  <div class="wrapper">
    <div class="modal-wrapper">
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Choose your bundle</h4>
      </div>
      <div class="modal-body py-4">
        <p class="mb-3">Select one of the bundles below and then click continue</p>
        <div class="row">
          <div class="col-md-6" (click)="selectBundleToPurchase(BillingProductPackageType.PEOPLE_SEARCH_1_CREDIT)" [class.selected]="selectedBundle === BillingProductPackageType.PEOPLE_SEARCH_1_CREDIT">
            <div class="card">
              <div class="card-content">
                <p class="mb-0 gray-txt">Individual searches</p>
                <h4>$2.99/search</h4>
              </div>
            </div>
          </div>
          <div class="col-md-6" (click)="selectBundleToPurchase(BillingProductPackageType.PEOPLE_SEARCH_100_CREDITS)" [class.selected]="selectedBundle === BillingProductPackageType.PEOPLE_SEARCH_100_CREDITS">
            <div class="card">
              <div class="card-content">
                <p class="mb-0 gray-txt">Bundles of 100 searches</p>
                <h4>$99/bundle</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-between">
        <button type="button" class="btn btn-secondary" (click)="close()">Cancel</button>
        <button type="button" class="btn btn-primary main-user-button" [disabled]="!selectedBundle || redirectingToStripe"
          (click)="openCheckoutSession(true)">Continue</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #loadingTemplate>
  <div class="loader">
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
  </div>
</ng-template>
